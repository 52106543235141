<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Filters
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>

        <!-- Name -->
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Name</label>
          <b-form-input
            :value="nameFilter"
            class="d-inline-block mr-1"
            @input="(val) => $emit('update:nameFilter', val)"
          />
        </b-col>

        <!-- CR Number -->
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>CR Number</label>
          <b-form-input
            :value="crNumberFilter"
            class="d-inline-block mr-1"
            @input="(val) => !val || val.length === 2 || val.length >= 10 ? $emit('update:crNumberFilter', val) : ''"
          />
        </b-col>

      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormInput,
  },
  props: {
    nameFilter: {
      type: [String, null],
      default: null,
    },
    crNumberFilter: {
      type: [String, null],
      default: null,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
