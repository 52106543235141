import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
// import useSystemCodes from '@/views/system-settings/system-codes/useSystemCodes'

export default function useRetailersList() {
  // Use toast
  const toast = useToast()

  const refRetailerListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'actions', sortable: false },
    { key: 'Owner', sortable: false },
    { key: 'BusinessName', sortable: false },
    { key: 'CRNumber', sortable: false, label: 'CR Number' },
    { key: 'TaxID', sortable: false, label: 'VAT Id' },
    { key: 'subscriptionStatus', sortable: false, class: 'text-center' },
    { key: 'status', sortable: false },
    {
      key: 'show_details',
      sortable: false,
      label: '',
      class: 'text-right',
    },
  ]
  const catalogueTableColumns = tableColumns.slice(1)
  const perPage = ref(10)
  const totalRecords = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('entityId')
  const isSortDirDesc = ref(true)
  const nameFilter = ref(null)
  const crNumberFilter = ref(null)
  const prevPage = ref(null)
  const nextPage = ref(null)

  // const dataMeta = computed(() => {
  //   const localItemsCount = refRetailerListTable.value ? refRetailerListTable.value.localItems.length : 0
  //   return {
  //     from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
  //     to: perPage.value * (currentPage.value - 1) + localItemsCount,
  //     of: totalRecords.value,
  //   }
  // })

  const setCurrentPage = value => {
    currentPage.value = value
  }

  const refetchData = () => {
    refRetailerListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, nameFilter, crNumberFilter], () => {
    refetchData()
  })

  // Call System Codes
  // const { callStatus } = useSystemCodes()
  // callStatus()

  const fetchRetailers = (ctx, callback) => {
    store
      .dispatch('app-retailer/fetchRetailers', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        name: nameFilter.value,
        CRNumber: crNumberFilter.value,
      })
      .then(response => {
        if (!response.data.data) {
          const records = []
          callback(records)
          return
        }
        const records = response.data.data[0].entities

        callback(records)
        prevPage.value = response.data.data[0].previous_page
        nextPage.value = response.data.data[0].next_page

        // totalRecords.value = response.data.data[0].total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Retailers list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchRetailers,
    tableColumns,
    catalogueTableColumns,
    perPage,
    currentPage,
    totalRecords,
    // dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refRetailerListTable,
    refetchData,
    prevPage,
    nextPage,
    setCurrentPage,

    //  Filters
    nameFilter,
    crNumberFilter,
  }
}
