<template>

  <div>

    <!-- Filters -->
    <retailers-list-filters
      :name-filter.sync="nameFilter"
      :cr-number-filter.sync="crNumberFilter"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

        </b-row>

      </div>

      <b-table
        ref="refRetailerListTable"
        class="position-relative"
        :items="fetchRetailers"
        responsive
        :fields="$route.name === 'retailers-management-retailers-list' ? tableColumns : catalogueTableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-if="$ability.can('V', 'Retailers & Branches')"
              :to="{ name: 'retailers-management-retailers-view', params: { id: data.item.entityId } }"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$ability.can('E', 'Retailers & Branches')"
              :to="{ name: 'retailers-management-retailers-edit', params: { id: data.item.entityId } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

        <template #cell(Owner)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :text="avatarText(data.item.OwnerName)"
                variant="light-secondary"
                style="cursor: pointer"
              />
            </template>
            <b-link class="font-weight-bold d-block text-nowrap">
              <span class="text-capitalize text-secondary">{{ data.item.OwnerName }}</span>
            </b-link>
            <small class="text-muted text-capitalize">{{ data.item.OwnerMobile }}</small>
          </b-media>
        </template>

        <!-- Column:  Subscription's Status -->
        <template #cell(subscriptionStatus)="data">
          <b-badge
            v-if="data.item.subscriptions && data.item.subscriptions.length > 0 && data.item.subscriptions[0].status"
            pill
            :variant="`light-${resolveSStatusVariant(data.item.subscriptions[0].status)}`"
            class="text-capitalize"
          >
            {{ resolveSStatus(data.item.subscriptions[0].status) }}
          </b-badge>

          <span
            v-else
            class="text-muted"
          >
            N/A
          </span>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            v-if="data.item.status"
            pill
            :variant="`light-${resolveStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ resolveStatus(data.item.status) }}
          </b-badge>
        </template>

        <!-- Column: Show Details -->
        <template #cell(show_details)="row">
          <b-button
            v-ripple.400="'rgba(53, 197, 240, 0.15))'"
            variant="flat-info"
            class="btn-icon rounded-circle"
            size="sm"
            @click="row.toggleDetails"
          >
            <feather-icon :icon="row.detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'" />
          </b-button>
        </template>

        <!-- Column: Row Details -->
        <template #row-details="data">
          <branches-list
            :table-columns="branchesTableColumn"
            :branches="data.item.branches"
            @refetch-data="refetchData"
          />

        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-button
              v-ripple.400="'rgba(255, 206, 38, 0.15)'"
              variant="light"
              size="sm"
              class="btn-icon rounded-circle text-muted page-item prev-item"
              :disabled="!prevPage"
              @click.prevent="setCurrentPage(prevPage)"
            >
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 206, 38, 0.15)'"
              variant="light"
              size="sm"
              class="btn-icon rounded-circle text-muted page-item next-item"
              :disabled="!nextPage"
              @click.prevent="setCurrentPage(nextPage)"
            >

              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </b-button>

            <!-- <b-pagination
              v-model="currentPage"
              :total-rows="totalRecords"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination> -->

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BMedia,
  BLink,
  BAvatar,
  BBadge,
  BDropdown,
  BDropdownItem,
  // BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import useRetailersList from './useRetailersList'
import retailerStoreModule from '../retailerStoreModule'
import BranchesList from '../branches/branches-list/BranchesList.vue'
import RetailersListFilters from './RetailersListFilters.vue'
import {
  resolveStatus,
  resolveStatusVariant,
  resolveSStatus,
  resolveSStatusVariant,
} from '@/utils/common-functions/resolvers'
import useBranchesList from '../branches/branches-list/useBranchesList'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BLink,
    BAvatar,
    BBadge,
    BDropdown,
    BDropdownItem,
    // BPagination,

    vSelect,

    //  Local Components
    BranchesList,
    RetailersListFilters,
  },
  directives: {
    Ripple,
  },
  setup() {
    const RETAILER_APP_STORE_MODULE_NAME = 'app-retailer'

    // Register module
    if (!store.hasModule(RETAILER_APP_STORE_MODULE_NAME)) store.registerModule(RETAILER_APP_STORE_MODULE_NAME, retailerStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(RETAILER_APP_STORE_MODULE_NAME)) store.unregisterModule(RETAILER_APP_STORE_MODULE_NAME)
    })

    const { tableColumns: branchesTableColumn } = useBranchesList()

    const {
      fetchRetailers,
      tableColumns,
      catalogueTableColumns,
      perPage,
      currentPage,
      totalRecords,
      // dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refRetailerListTable,
      refetchData,
      prevPage,
      nextPage,
      setCurrentPage,

      //  Filters
      nameFilter,
      crNumberFilter,

      //   UI
      // resolveStatus,
      // resolveStatusVariant,
    } = useRetailersList()

    return {
      fetchRetailers,
      tableColumns,
      catalogueTableColumns,
      perPage,
      currentPage,
      totalRecords,
      // dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refRetailerListTable,
      refetchData,
      prevPage,
      nextPage,
      setCurrentPage,

      //   Filters
      nameFilter,
      crNumberFilter,

      branchesTableColumn,

      //   UI
      avatarText,
      resolveStatus,
      resolveStatusVariant,
      resolveSStatus,
      resolveSStatusVariant,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
